<template>
  <div class="page">
    <nav-top :index="0"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="#">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">新闻列表</router-link>
          </div>
        </div>
        <div class="search">
          <div class="search-text">
            <div class="text">
              <input type="text" placeholder="请输入关键词" v-model="keyword">
            </div>
            <div class="btn" @click="handleSearchClick">
              <img src="/static/images/fdj_ico2.png" alt="">
            </div>
          </div>
          <router-link class="report-btn" to="/report">
            <span>快速报备</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="look-info">
      <div class="look-title">
        <div class="title">
          <span>新闻列表</span>
          <span>/ NEWS LIST</span>
        </div>
      </div>
      <div class="look-list" v-loading="loading">
        <div class="list" v-for="(item,index) in list" :key="index" @click="handleItemClick(index)">
          <div class="pic">
            <img :src="item.image" alt="">
          </div>
          <div class="item">
            <div class="title">
              <span>{{ item.title }}</span>
            </div>
            <div class="date-tag">
              <div class="date">
                <span>{{ item.create_date }}</span>
              </div>
              <div class="tag">
                <span>{{ item.type_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paging-info">
        <div class="pagination">
          <div class="num" :class="{active:item === page.current}" v-for="item in page.last" :key="item"
               @click="handlePageClick(item)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="paging">
          <div class="cut" @click="handlePagePrevClick" v-show="page.current !== 1">
            <span class="left"></span>
          </div>
          <div class="cut" @click="handlePageNextClick" v-show="page.current !== page.last">
            <span class="right"></span>
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'newsList',
  components: {FooterBar, NavTop},
  data() {
    return {
      loading: false,
      keyword: '',
      page: {
        current: 1,
        last: 1,
      },
      list: []
    }
  },
  created() {
  },
  mounted() {
    this.keyword = this.$route.query.keyword
    this.init()
  },
  methods: {
    init() {
      this.fetchData()
    },
    async fetchData() {
      this.loading = true
      try {
        let res = await this.http({
          url: '/api/index/search',
          method: 'GET',
          params: {
            page: this.page.current,
            keyword: this.keyword
          }
        })

        this.page.current = res.data.page.current
        this.page.last = res.data.page.last
        this.list = res.data.list
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handlePageClick(page) {
      this.page.current = page
      this.fetchData()
    },
    handlePagePrevClick() {
      this.page.current--
      this.fetchData()
    },
    handlePageNextClick() {
      this.page.current++
      this.fetchData()
    },
    handleSearchClick() {
      this.$router.push({
        path: '/newsList',
        query: {
          keyword: this.keyword
        }
      })
    },
    handleItemClick(index) {
      let id = this.list[index].id

      switch (this.list[index].type)
      {
        case 3:
          this.$router.push({
            path: '/lookDetails',
            query: {
              id: id
            }
          })
          break
        case 1:
          this.$router.push({
            path: '/hotspotDetails',
            query: {
              id: id
            }
          })
          break
      }
    }
  },
  watch:{
    '$route.query':function (e){
      this.keyword = e.keyword
      this.init()
    }
  }
}
</script>

<style scoped lang="scss">
@import "newsList.scss";
</style>
